import type { Ref } from 'vue';
import { StatusCodes } from 'http-status-codes';
import type { Entry } from 'contentful';
import type { EntryWithLinkData, LOCALE_CODE } from '~/lib/ContentfulService';
import { resolveEntry } from '~~/utils/resolveEntry';
import { Tracer } from '~/lib/Tracing';
import type { IPage } from '~/utils/guards/isContentPage';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';

export type UseContentfulPageResponseModel = Ref<{
  page: IPage;
  /**
   * Contains a locale slug map which maps a locale to a slug in that locale.
   * This map may contain `null` as entry whereas there actually exists
   * a matching slug, as resolving the link map is allowed to fail
   * in the api route.
   */
  localeSlugMap: Partial<Record<LOCALE_CODE, string>>;
}>;

export function useContentfulPage({
  id,
  slug,
  locale
}: {
  id?: string;
  slug?: string;
  locale: string | undefined;
}): Promise<UseContentfulPageResponseModel> {
  const tracer = new Tracer('composables');

  return tracer.withSpan(
    'useContentfulPage',
    {
      'parameter.id': id,
      'parameter.slug': slug,
      'parameter.locale': locale
    },
    async () => {
      const { data, error } = await useFetch('/api/contentful', {
        ...DEFAULT_FETCH_OPTIONS,
        query: {
          id,
          slug,
          locale
        }
      });

      if (error.value) {
        throw createError({
          statusCode: error.value.statusCode,
          cause: error.value,
          fatal: true
        });
      }

      if (!data.value) {
        throw createError({
          statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
          message: 'failed to load page',
          fatal: true
        });
      }

      return computed(() => ({
        page: resolveEntry(
          data.value!.entry as EntryWithLinkData<Entry<unknown>>,
          true
        ) as IPage,
        localeSlugMap: data.value!.localeSlugMap
      }));
    }
  );
}
